import { createContext, useContext } from 'solid-js';
import { isServer } from 'solid-js/web';
import type PostHog from 'posthog-js';
import type { PostHog as PostHogNode } from 'posthog-node';
import type { ParentProps } from 'solid-js';

type PostHogWeb = typeof PostHog;

const Context = createContext<{
	posthog: PostHogWeb | undefined;
	flags: Record<string, boolean | string>;
	overrides: Record<string, boolean | string>;
}>();

type Props = ParentProps & {
	posthog?: PostHogWeb | PostHogNode;
	flags?: Record<string, boolean | string>;
	overrides?: Record<string, boolean | string>;
};

export function AnalyticsProvider(props: Props) {
	return (
		<Context.Provider
			value={{ posthog: props.posthog as PostHogWeb, flags: props.flags ?? {}, overrides: props.overrides ?? {} }}
		>
			{props.children}
		</Context.Provider>
	);
}

export function useAnalytics() {
	const ctx = useContext(Context);
	return ctx?.posthog;
}

export function useIsEnabled(key: string, defaultValue: boolean = false, sendEvents: boolean = true) {
	const ctx = useContext(Context);
	if (key in (ctx?.overrides ?? {})) {
		return !!ctx!.overrides[key];
	}

	if (isServer) {
		return !!ctx?.flags[key];
	}

	return !!(ctx?.posthog?.isFeatureEnabled(key, { send_event: sendEvents }) ?? defaultValue);
}

export function useTrackEvent(): Track {
	const ctx = useContext(Context);
	if (!ctx?.posthog) {
		return () => {};
	}

	return (event: AnalyticsEvent, properties?: Record<string, unknown>) => {
		ctx!.posthog!.capture(event, properties);
		if (event === 'logOutSuccess') {
			// Reset the user to the analytics platform
			// https://posthog.com/docs/libraries/js
			ctx!.posthog!.reset();
		}
	};
}

const noop = () => undefined;

export function useCapture(): PostHogWeb['capture'] {
	const ctx = useContext(Context);
	if (!ctx?.posthog) {
		return noop;
	}

	return ctx.posthog.capture.bind(ctx.posthog.capture);
}

export type AnalyticsRequestEvents =
	// Auth
	| 'login'
	| 'logOut'
	| 'signup'
	| 'emailMagicLink'
	| 'confirmEmailMagicLink'
	// Password reset
	| 'passwordResetCode'
	| 'passwordReset'
	// Tee times
	| 'getTeeTimes'
	| 'reserveTeeTime'
	| 'createTeeTimeAlert'
	| 'updateTeeTimeAlert'
	| 'deleteTeeTimeAlert'
	// Reservation Actions
	| 'attemptMobileCheckIn'
	| 'completeMobileCheckIn'
	// Inviting Users
	| 'inviteShare'
	| 'inviteUser'
	| 'removeUser'
	// Invite Actions
	| 'acceptInvitation'
	| 'rejectInvitation'
	// Reservation Actions
	| 'leaveReservation'
	| 'cancelReservation'
	// Check in
	| 'checkIn'
	// Credit cards
	| 'getCreditCardToken'
	| 'storeCreditCard'
	| 'addStoredCreditCard'
	// misc
	| 'getSupport'
	// Stripe
	| 'checkout'
	| 'applyPromoCode';

export type AnalyticsEvent =
	| `${AnalyticsRequestEvents}Success`
	| `${AnalyticsRequestEvents}Request`
	| `${AnalyticsRequestEvents}Failure`
	// Dialogs
	| 'dialogOpened'
	| 'dialogClosed'
	// Sessions Events
	| 'sessionStart'
	| 'sessionEnd'
	// SignUp
	| 'signupEnteredEmail'
	| 'signupEnteredPassword'
	| 'signupEnteredName'
	| 'signupEnteredZipcode'
	// Screen Events
	| 'screenAppear'
	| 'screenDisappear'
	// Teetime Actions
	| 'changeTeeTimeDay'
	| 'didSelectTeeTime'
	| 'addToCalendar'
	// Push Actions
	| 'registerForPushAccess'
	| 'pushAccess'
	| 'receivedPushNotification'
	| 'tappedPushNotification'
	// Deeplinks
	| 'tappedDeeplink'
	// Payments
	| 'changePaymentType'
	| 'paymentInfoComplete';

export type Track = (event: AnalyticsEvent, properties?: Record<string, unknown>) => void;
